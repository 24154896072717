import axios from "axios";

// Function to get user location using Geolocation API
export function getUserLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          return(`User Location: Latitude ${latitude}, Longitude ${longitude}`);
        },
        (error) => {
          return(`Error getting location: ${error.message}`);
        }
      );
    } else {
      return('Geolocation is not supported by this browser.');
    }
  }
  
  // Function to get device and platform information
export function getDeviceInfo() {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const isMobileDevice = /Mobi/.test(userAgent);
  
    return(`Device Info: User Agent: ${userAgent}, Platform: ${platform}, Is Mobile Device: ${isMobileDevice}`);
  }

  
export function sendErrorNotification(full_name, email, error) {
    // Call the functions to get location and device info
    let userLocation = getUserLocation();
    let deviceInfo = getDeviceInfo();

    axios.post('https://crm.nativetalkapp.com:447/flsk/cy-mail ', {
        "to_email": "support@cyberstick.io",
        "email_template": "error_template1",
        "full_name": full_name,
        "email": email,
        "extra_info": `${userLocation} \n ${deviceInfo}`,
        "error": error
    }, {headers: {
        'Authorization': '919c02f004339822ecb967cc526977b528f7ba55'
        }}).then((res) => console.log("Sent error to support"))
        .catch((err) => console.log("notification error ", err))
}