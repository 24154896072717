import React from "react";
import axios from "axios";
import { useParams, useLocation, Navigate } from "react-router-dom";
import $ from 'jquery';

import { ScrollToTopOnMount, CheckoutForm, Header } from "./Bases";


class Shipping extends React.Component {
    constructor(props) {
        $('#spinner').removeClass('hidden');
        sessionStorage.removeItem('PIP'); 
        super(props)
        const state_initials = props.location.state
        this.state = {
            ...state_initials, 
            checkout: false,
            backtrace: false,
            city_state: 'Lagos Island',
        }
    }

    submitInfo = (e) => {
        if (!sessionStorage.getItem('new_customer')) {
            $('#spinner').removeClass('hidden');
            axios.put(`https://backend.nativetalkapp.com:459/cyberstick/orders/${sessionStorage.getItem('order_token')}`, {
            // axios.put(`http://127.0.0.1:8000/cyberstick/orders/${sessionStorage.getItem('order_token')}`, {    
                total_price: this.state.price + this.state.shipping,
            }).then((res) => {
                axios.post('https://backend.nativetalkapp.com:459/cyberstick/customers/', {
                // axios.post('http://127.0.0.1:8000/cyberstick/customers/', {
                    first_name: $('#first_name').val(),
                    last_name: $('#last_name').val(),
                    street_address: $('#street_address').val(),
                    country: $('#country').val(),
                    city_state: this.state.city_state, 
                    email: $('#email').val(),
                    phone_number: $('#phone_number').val(),
                    order_id: sessionStorage.getItem('order_token'),
                })
                .then((res) => {
                    sessionStorage.setItem('customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
                    sessionStorage.setItem('new_customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
                    this.setState({checkout: true})
                })
                .catch((err) => {
                    $('#spinner').addClass('hidden'); 
                    alert(err.message); 
                    // sessionStorage.clear();
                    this.setState({backtrace: true})
                })
            })
            .catch((err) => {
                $('#spinner').addClass('hidden');
                alert(err.message);
                // alert('error')
                // sessionStorage.clear()
                this.setState({backtrace: true})
            })
        }
    }   

    componentDidMount() {
        $('#spinner').addClass('hidden');
        $('body').removeClass('no-scroll');
        sessionStorage.setItem('state', this.state.city_state)
    }

    componentDidUpdate() {
        sessionStorage.setItem('state', this.state.city_state)
    }

    forceUpdate() {
        this.setState({price: Number(sessionStorage.getItem('price')), shipping: sessionStorage.getItem('shipping'),
            tax:'-', total_price: (Number(sessionStorage.getItem('price')) + Number(sessionStorage.getItem('shipping')) ) || Number(sessionStorage.getItem('total_price')) })
    }

    render() {
        if (this.state.price || this.state.total_price) {}
        else if (sessionStorage.getItem('order_token') && sessionStorage.getItem('amount'))
            this.forceUpdate()
        else return <Navigate to='/checkout' />
        
        // if (sessionStorage.getItem('new_customer'))
        //     return <Navigate to='/payment' state={{shipping: this.state.shipping, price: this.state.price,
        //         tax: this.state.tax, total_price: this.state.total_price}} />
        if (this.state.checkout)
            return <Navigate to='/payment' state={{shipping: this.state.shipping, price: this.state.price,
                tax: this.state.tax, total_price: this.state.total_price}}
            />
        // else if (this.state.backtrace) return <Navigate to='/checkout' />
        return (
            <div>
                <ScrollToTopOnMount />
                <Header />

                <div className="container-fluid">
                <section className='row checkout-section-1
                justify-content-around align-items-start g-0px text-sm-left text-center'>
                    <div className="col-md-6 col-12">
                        <div className="p-5 pb-30px">
                            <h3 className="fw-400">Shipping</h3> 
                            <p className="py-2 ">Where Should We Send Your Order?</p>
                        </div>    
                        <form className="form px-sm-5 px-1" onSubmit={(e) => {e.preventDefault(); this.submitInfo(e)}}>
                            <p>Enter Your Name And Address:</p>    
                            <div className="form-group">    
                                <input id="first_name" type="text" className="form-control" placeholder="First Name" required/>    
                            </div>
                            <div className="form-group">    
                                <input id="last_name" type="text" className="form-control" placeholder="Last Name" required/>
                            </div>
                            <div className="form-group Autocomplete">
                                <input type="text" id="street_address" className="form-control" placeholder="Street Address" 
                                onChange={(e) => {
                                    sessionStorage.setItem('address', e.target.value)
                                }} required/>
                            </div>
                            <div className="row" id="street_extra">
                                {/* <div className="col form-group">
                                    <input type="text" id="city_state" className="form-control" placeholder="City/State" required/>
                                </div> */}
                                <div className="col form-group">
                                    <select onChange={(e) => {
                                        let index = e.target.options.selectedIndex;
                                        let shipping = e.target.options[index].value
                                        let state = e.target.options[index].text
                                        this.setState({shipping: shipping, city_state: state});
                                        sessionStorage.setItem('shipping', shipping);
                                    }} class="form-select col form-select-lg pl-3" aria-label="" title="State" id="city_state">
                                        <option value="3000">Abia State</option>
                                        <option value="3000">Adamawa State</option>
                                        <option value="3000">Akwa Ibom State</option>
                                        <option value="3000">Anambra State</option>

                                        <option disabled></option>
                                        <option value="3000">Bauchi State</option>
                                        <option value="3000">Bayelsa State</option>
                                        <option value="3000">Benue State</option>
                                        <option value="3000">Borno State</option>
                                        
                                        <option disabled></option>
                                        <option value="3000">Cross River State</option>
                                        <option value="3000">Delta State</option>

                                        <option disabled></option>
                                        <option value="3000">Ebonyi State</option>
                                        <option value="3000">Edo State</option>
                                        <option value="3000">Ekiti State</option>
                                        <option value="3000">Enugu State</option>

                                        <option disabled></option>
                                        <option value="3000">Gombe State</option>
                                        <option value="3000">Imo State</option>
                                        <option value="3000" >Jigawa State</option>

                                        <option disabled></option>
                                        <option value="3000">kaduna State</option>
                                        <option value="3000">Kano State</option>
                                        <option value="3000">Katsina State</option>
                                        <option value="3000">Kebbi State</option>
                                        <option value="3000">Kogi State</option>
                                        <option value="3000">Kwara State</option>

                                        <option disabled></option>
                                        <option value="1500" selected>Lagos State</option>

                                        <option disabled></option>
                                        <option value="3000">Nasarawa State</option>
                                        <option value="3000">Niger State</option>

                                        <option disabled></option>
                                        <option value="3000">Ogun State</option>
                                        <option value="3000">Ondo State</option>
                                        <option value="3000">Osun State</option>
                                        <option value="3000">Oyo State</option>

                                        <option disabled></option>                                        
                                        <option value="3000">Plateau State</option>
                                        <option value="3000">Rivers State</option>
                                        <option value="3000">Sokoto State</option>
                                        <option value="3000">Taraba State</option>
                                        <option value="3000">Yobe State</option>
                                        <option value="3000">Zamfara State</option>
                                        {/* <option value="0">Test</option>  */}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <input type="text" id="country" className="form-control" value='Nigeria' placeholder="Country" disabled required/>
                            </div>

                            <p className="mt-50px">What's Your Contact Information?</p>                        
                            <div className="form-group">
                                <input type="email" id="email" className="form-control"
                                placeholder="Email Address" required/>
                            </div>
                            <div className="form-group">
                                <input type="text" maxLength="15" id="phone_number" className="form-control"
                                placeholder="Phone Number" onChange={(e) => {
                                    sessionStorage.setItem('phone_number', e.target.value)
                                }} required/>
                            </div>
                            <select
                            class="form-select form-select-lg w-100 pl-3" aria-label="feedback"
                            onChange={(e) => {
                                let index = e.target.options.selectedIndex;
                                let advert_mode = e.target.options[index].value
                                sessionStorage.setItem('advert_mode', advert_mode);}}>
                                <option value="none" className="text-muted">How Did You Hear About Us?</option>    
                                <option value="Internet Ads">Internet Ads </option>
                                <option value="Social Media">Social Media</option>
                                <option value="In Person Events">In Person Events</option>
                                <option value="Television">Television</option>
                                <option value="Flyers/Billboards">Flyers/Billboards</option>
                                <option value="Others">Other</option>
                            </select>
                            
                            <button className='checkout-btn  mt-20px' type='submit' value='Checkout'>
                                Proceed to Payment
                            </button>
                        </form>
                    </div>    
                    <div className="mt-30px col-12 col-md-4">
                        <CheckoutForm header="your order total" price={this.state.price}
                            shipping={this.state.shipping} tax={this.state.tax}
                            total_price={this.state.total_price} />
                    </div>
                </section>
                </div>
            </div>
        )
    }    
}

export default function Shipping_wrapper() {
    return <Shipping params={useParams()} location={useLocation()} />
}
// import React from "react";
// import axios from "axios";
// import { useParams, useLocation, Navigate } from "react-router-dom";
// import $ from 'jquery';

// import { ScrollToTopOnMount, CheckoutForm } from "./Bases";

// class Shipping extends React.Component {
//     constructor(props) {
//         $('#spinner').removeClass('hidden');
//         sessionStorage.removeItem('PIP'); 
//         super(props)
//         const state_initials = props.location.state
//         this.state = {
//             ...state_initials, 
//             checkout: 'no'
//         }
//     }

//     submitInfo = (e) => {
//         if (!sessionStorage.getItem('new_customer')) {
//             $('#spinner').removeClass('hidden');
//             //axios.post('https://cyberstick-backend.herokuapp.com/cyberstick/customers/', {
//             axios.post('http://127.0.0.1:8000/cyberstick/customers/', {
//                 first_name: $('#first_name').val(),
//                 last_name: $('#last_name').val(),
//                 street_address: $('#street_address').val(),
//                 country: $('#country').val(),
//                 city_state: $('#city_state').val(), 
//                 email: $('#email').val(),
//                 phone_number: $('#phone_number').val(),
//                 order_id: sessionStorage.getItem('order_token'),
//             })
//             .then((res) => {
//                 sessionStorage.setItem('customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
//                 sessionStorage.setItem('new_customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
//                 this.setState({checkout: 'yes'})
//             })
//             .catch((err) => {alert(err.message); $('#spinner').addClass('hidden');})
//         }
//     }   

//     componentDidMount() {
//         $('#spinner').addClass('hidden');
//     }

//     forceUpdate() {
//         this.setState({price: Number(sessionStorage.getItem('amount') * 25000), shipping: 'Free',
//             tax:'-', total_price: Number(sessionStorage.getItem('amount') * 25000)})
//     }

//     render() {
//         if (this.state.price || this.state.total_price) console.log('')
//         else if (sessionStorage.getItem('order_token') && sessionStorage.getItem('amount'))
//             this.forceUpdate()
//         else return <Navigate to='/checkout' />
        
//         if (sessionStorage.getItem('new_customer'))
//             return <Navigate to='/payment' state={{shipping: this.state.shipping,
//                 tax: this.state.tax, total_price: this.state.total_price}} />
//         if (this.state.checkout === 'yes')
//         <Navigate to='/payment' state={{shipping: this.state.shipping,
//             tax: this.state.tax, total_price: this.state.total_price}}
//         />
//         return (
//             <div>
//                 <ScrollToTopOnMount />
//                 <div className="container-fluid">
//                 <section className='row checkout-section-1
//                 justify-content-around align-items-start g-0px text-sm-left text-center'>
//                     <div className="col-md-6 col-12">
//                         <div className="p-5 pb-30px">
//                             <h3 className="fw-400">Shipping</h3> 
//                             <p className="text-muted py-2 ">Where Should We Send Your Order?</p>
//                         </div>    
//                         <form className="form px-sm-5 px-1" onSubmit={(e) => {e.preventDefault(); this.submitInfo(e)}}>
//                             <p>Enter Your Name And Address:</p>    
//                             <div className="form-group">    
//                                 <input id="first_name" type="text" className="form-control" placeholder="First Name" required/>    
//                             </div>
//                             <div className="form-group">    
//                                 <input id="last_name" type="text" className="form-control" placeholder="Last Name" required/>
//                             </div>
//                             <div className="form-group">
//                                 <input type="text" id="street_address" className="form-control" placeholder="Street Address" required/>
//                             </div>
//                             <div className="form-group">
//                                 <input type="text" id="country" className="form-control" value='Nigeria' placeholder="Country" disabled required/>
//                             </div>
//                             <div className="row" id="street_extra">
//                                 <div className="col">
//                                     <input type="text" id="city_state" className="form-control" placeholder="City/State" required/>
//                                 </div>    
//                             </div>
                            
                    
//                             <p className="mt-50px">What's Your Contact Information?</p>                        
//                             <div className="form-group">
//                                 <input type="email" id="email" className="form-control"
//                                 placeholder="Email Address" required/>
//                             </div>
//                             <div className="form-group">
//                                 <input type="text" maxLength="15" id="phone_number" className="form-control"
//                                 placeholder="Phone Number" required/>
//                             </div>
//                             <button className='checkout-btn  mt-20px' type='submit' value='Checkout'>
//                                 Proceed to Payment
//                             </button>
//                         </form>
//                     </div>    
//                     <div className="mt-30px col-12 col-md-4">
//                         <CheckoutForm header="your order total" price={this.state.total_price}
//                             shipping={this.state.shipping} tax={this.state.tax}
//                             total_price={this.state.total_price} />
//                     </div>
//                 </section>
//                 </div>
//             </div>
//         )
//     }    
// }

// export default function Shipping_wrapper() {
//     return <Shipping params={useParams()} location={useLocation()} />
// }