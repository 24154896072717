// Import external modules and components
import React from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';

// Import custom modules, functions, variables and components
import { Features, Steps, Faq, ScrollToTopOnMount, RemoteFeatures, AdsFeatures, AdsSteps } from './Bases';

// Import custom styles
import './static/Home.scss';

// Import external styles
import 'animate.css';

// Import image files
// import cyberstick from './static/cyberstick front and back 1.png';
// import cyberstick from './static/cyberstick remote 2d 2.png';
import cyberstick from './static/cyberstick_n_remote_3.png';
import cyberstick_n_remote from './static/cyberstick_n_remote_4.png';
import cyberstick_designed from './static/cyberstick_designed.png';

import tvbg1 from './static/tvbg1.png';
import tvbg2 from './static/tvbg2.png';
import bgCyberstick2 from './static/bg-cyberstick1.png';
import { AdsHeader } from './Bases';


// Set a cookie given its name(cname) and its value(cvalue) and amount of days(exdays)
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires"+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";";
}

// Get a cookie value given its name(cname)
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while(c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Return true if a cooke exists else false
function checkCookie(cname) {
    if(getCookie(cname) !== "") return true
    else return false
}

// (Home component) Display the landing page
export default class Home extends React.Component {
    constructor() {
        super()
        $('#spinner').removeClass('hidden');
        // sessionStorage.removeItem('PIP');    
    }    
    
    componentDidMount() {
        $('#spinner').addClass('hidden');
        $('body').removeClass('no-scroll');
        $('#success_overlay').addClass('hidden');
        $('#failed_overlay').addClass('hidden');
    }

    componentWillUnmount() {
        $('#spinner').removeClass('hidden');
    }

    // Hide an element by hiding its parent. Used to hide the cookie policy popup    
    removePopup = (e) => {
        e.target.parentElement.classList.add('hidden')
    }
        
    render() {
        return (
            <div className='landing ads-landing overflow-hidden' id='overview'>
            {/* <ScrollToTopOnMount /> */}
            <AdsHeader />
                <section className='home-section-1 d-flex flex-column ads-header'>
                    <div className='container-fluid pl-7vw pr-7vw '>   
                        <div className='row mt-170px mb-100px position-relative home-section-1-row'>
                            <div className='col-12 align-items-start justify-content-center'>
                                <h1 className='think-text0'>Keep Up With The Trend</h1>
                                <h1 className='think-text1 cyberstick_designed bg-success py-0'>Get yourself <img width={300} src={cyberstick_designed} /></h1>
                                <h1 className='think-text1 mt-0 pt-0 bg-danger'>and experience unlimited fun</h1>
                                
                                <Link to= "/OrderDetails">
                                    <button className='buynowbtn'>
                                        Buy Now
                                    </button>
                                </Link>
                            </div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-12 mt-5'>
                                <img className='cyberstick' alt='cyberstick' src={cyberstick} />
                            </div>
                          </div>
                    </div>
                </section>
                
                <section className='d-flex flex-column align-items-center mt-5 px-3'>
                    <h1 className='think-text0 mt-5 '>
                        The most powerful
                    </h1>
                    <h1 className='think-text0'>
                      streaming stick on the market
                    </h1>
                    <h1 className='fw-400 big-text2'>A Device that turns any TV into a smart TV</h1>
                    <Link to= "/OrderDetails" style={{width: '100%'}}>
                        <button className='buynowbtn'>
                            Buy Now
                        </button>
                    </Link>
                    <img src={tvbg1} alt='tvshow' className="tvbg1"/>
                </section>
                
              
                <div className='position-relative' style={{'top': '100px'}}>
                    <AdsFeatures />
                </div>
                <div className='position-relative mt-20px'>
                    <AdsSteps />
                </div>
                       
                <section className="add-section-1 container-fluid px-md-5 px-xs-0 rounded mt-150px">
                  <div className="row mx-0 px-md-5 mx-md-5 mx-xs-0 px-xs-3 py-4 rounded bg-dark  justify-content-around align-items-center "
                 >
                      <div className="col-12 col-md-8 d-flex flex-column align-items-start">
                             <h1 className='exp think-text1'>Order yours today</h1>
                            <h1 className="display-6 fw-400 big-text2">For a discounted offer of</h1>
                            <h1 className='think-text2 mt-2'>₦ 35,000</h1>   
                            <Link to= "/OrderDetails">
                                    <button className='buynowbtn' style={{top: '0px'}} >
                                        Buy Now
                                    </button>
                                </Link>
                      </div>
                      <div className="col-12 col-md-4 position-relative">
                         <img src={cyberstick_n_remote} className='position-absolute' loading="lazy"/>
                      </div>
                  </div>
              </section>  

              <section className='mt-200px'>
                    <img width={300} src={cyberstick_designed} />  
                     <h1 className='think-text1'>Gives you value for your money’s worth</h1>
                     <h1 className='fw-400 big-text2'>Don’t believe me? See what our Customers have to say</h1>
              </section>
            
              <section className='reviews px-md-5 px-xs-0'>
                <div className='d-flex'>
                    <div className='p-5 p-xs-0 d-flex rounde bg-dark flex-column  align-items-start text-left my-4 mr-4 ml-5'>
                        <div className='py-3 px-xs-0 px-md-5 review '>
                            <h2>Mr. Afolayan</h2>
                            <p className='mt-4 fs-1p2em'>
                                One of my favourite features is the offline mode, like I've been having network issues but the Cyberstick has kept me entertained because of the previous downloads I made. So, I'm happy with my purchase.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 p-xs-0 d-flex rounded flex-column bg-dark align-items-start text-left m-4'>
                        <div className='px-xs-0 py-3 px-md-5 review'>
                            <h2>Benedict</h2>
                            <p className='mt-4 fs-1p2em'>
                              I like the fact that it's light weight and portable, because I travel a lot and I can't miss out on my favourite shows. So, I'll say it was a
good purchase.
                            </p>
                        </div>
                    </div>
                    {/* <div className='p-5 d-flex rounded flex-column bg-dark align-items-start text-left m-4'>
                        <div className='p-3 px-5 review'>
                            <h2>Benedict</h2>
                            <p className='mt-4 fs-1p2em'>
                              I like the fact that it's light weight and portable, because I travel a lot and I can't miss out on my favourite shows. So, I'll say it was a
good purchase.
                            </p>
                        </div>
                    </div> */}
                </div>
                
              </section>

                <div className='mt-200px mp-5'>
                    <Faq />
                </div>
                {(() => { if (!checkCookie('ft')) {
                    setCookie('ft', 'set')    
                    return (
                        <div className="alert alert-dark alert-dismissible fade show
                        animate__animated animate__bounce fixed-bottom" role="alert">
                        This site uses cookies to enhance user experience <a style={{color: 'black'}} target="blank" href='https://l.facebook.com/l.php?u=http%3A%2F%2Fwww.aboutads.info%2Fchoices%3Ffbclid%3DIwAR1YKgNyzXOQT9XiFSK8MKS1PonXW-TbFvtZL7sGYkEEybQ2UVbNtosY1CY&h=AT1IERkzcZgRKh0DD8Mi6MG0DKq8WwjnvZlg7HCsyPPbcRZuwVAGHLM4BdDy1fp_yPYbg1SQ9D9Cr_-jTOpsd6NDWuOHcBXVqDsNmZf6vDKC-F-uOgugbDR2EiVz_5Op7K-p1w'>
                            <b>Learn more. </b>
                        </a>
                        <button type="button" className="btn btn-dark" data-dismiss="alert" aria-label="Close" onClick={this.removePopup}>
                            okay
                        </button></div>
                        )
                    }}
                )()}
            </div>
        )
    }
}
