import { Link } from "react-router-dom"
import tick from "../static/tick.png";
import cyberstickSkewed from "../static/cyberstickSkewed.png";
import HideElement from "../Utils/HideElement";

export default function SoldOut() {
    return (
        <div className="card validation-card pb-30px mx-auto Soldout fixed hidden" id='soldout_overlay'>
            <div className="card-body text-center">
                <div 
                    className="text-right" 
                    onClick={() => HideElement('soldout_overlay')}
                > 
                    <span className="close-card">&times;</span>
                </div>    
            
                <img src={cyberstickSkewed} className="img0" alt="" />
                <h3 className="display-3">Out Of Stock!!</h3>
                <p className="my-1">Pre order now at</p>
                <h4 className="my-1">₦47,500</h4>
                <p className="my-1">Orders will be available from the 30th of January</p>
                <Link 
                    to='/OrderDetails'
                    onClick={() => HideElement('soldout_overlay')}
                >
                    <button className='checkout-btn validation-btn py-3 mt-30px'>Pre-Order Now!</button>
                </Link>
            </div>
        </div>
    )
}