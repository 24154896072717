// Import external modules and components
import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import NumberFormat from 'react-number-format';
import $ from 'jquery';

// Import custom styles
import './static/Home.scss';

// Import image files
import copyright from './static/copyright.png';
import logo from './static/Group_20logo.svg';
import cart from './static/Cart.svg';
import steparrow1 from './static/steparrow1.png';
import steparrow2 from './static/steparrow2.png';
import step2 from './static/step2.png';
import step3 from './static/step3.png';
import frame1 from './static/frame1.png';
import frame2 from './static/frame2.png';
import frame3 from './static/frame3.png';
import cyberstick_on_cylinder from './static/cyberstick_on_cylinder.png';
import cyberstick_on_cylinder2 from './static/cyberstick_on_cylinder2.png';
import storage_icon from './static/ic_storage.svg';
import ic_4k from './static/ic_4k.svg';
import ic_stream from './static/ic_stream.svg';
import ic_quad from './static/ic_quad-core.svg';

import offline_mode_icon from './static/ic_offline.svg';
import play_store_icon from './static/ic_playstore.svg';
import productivity_icon from './static/ic_settings.svg';
import portability_icon from './static/ic_portable.svg';
import entertainment_icon from './static/ic_entertainment.svg';
import bluetooth_connectivity_icon from './static/ic_bluetooth.svg';
import wifi_connectivity_icon from './static/ic_wifi.svg';
import skewed_remote from './static/skewed_remote.png';
import cyberstick_designed from './static/cyberstick_designed.png';

import ic_01 from './static/ic_01.png';
import ic_02 from './static/ic_02.png';
import ic_03 from './static/ic_03.png';
import ic_01img from './static/ic_01img.png';
import ic_02img from './static/ic_02img.png';
import ic_03img from './static/ic_03img.png';


// Header component (Display the header)
function Header() {
    return (
        <header className='fixed-top header d-md-flex flex-row align-items-center'>
            <Link to='/' className='logo'>
                <img src={logo} alt="logo"/>
            </Link>
            <nav className='nav' id='header-nav'>
                <div className='nav-wrapper d-flex align-items-center'>
                    <span className='header-link'>
                        <HashLink to='/#overview'
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth'} )}>
                            Overview
                        </HashLink>    
                    </span>
                    <span className='header-link text-muted specs-span'>
                        <HashLink to='/#faq' 
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth'} )}
                        >
                            FAQ
                        </HashLink>    
                    </span>
                    {/* <Link to= "/" className=''>
                        <button className='buynowbtn header-link position-absolute'>
                            Buy Now
                        </button>
                    </Link> */}
                    
                    {/* <span className='header-link'>
                        <Link to= "/OrderDetails">
                            <img src={cart} alt='product'/>
                        </Link>    
                    </span> */}
                </div>
            </nav>
            <Sidebar />
        </header>
        )    
}

// Header for ads 
function AdsHeader() {
    return (
        <header className='fixed-top header d-md-flex flex-row align-items-center'>
            <Link to='/' className='logo'>
                <img src={logo} alt="logo"/>
            </Link>
            <nav className='nav' id='ads-header-nav'>
                <div className='nav-wrapper d-flex align-items-center justify-content-end'>
                    <Link to= "/OrderDetails" className='header-link position-absolute'>
                        <button className='buynowbtn'>
                            Buy Now
                        </button>
                    </Link>                 
                </div>
            </nav>
            <Sidebar />
        </header>
        )    
}

// Sidebar component (Display sidebar for mobile)
function Sidebar() {
    const [sidebarState, setSidebarState] = useState('closed'); // Keeps track of the state of the sidebar (opened or closed)

    return (
        <div>
            <div className="sidebar-toggle" id="sidebar-toggle" onClick={ () => {
                $('#sidebar').toggleClass('hidden');    
                $('#sidebar-toggle').toggleClass('close');
                if (sidebarState === 'closed') {    
                    setSidebarState('opened');
                } else {
                    setSidebarState('closed');
                }
            }}>
                <div className="toggle-line t1 mb-5px"></div>
                <div className="toggle-line t2 mb-5px"></div>
                <div className="toggle-line t3 mb-5px"></div>
            </div>
            <div className='d-flex flex-column py-5 px-4 sidebar position-absolute hidden' id='sidebar'>
                <HashLink to='/#overview'
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth'} )}>
                    <div className='border-bottom pb-1 pt-3'>Overview</div>
                </HashLink>    
                <HashLink to='/#faq' scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                    >
                    <div className='border-bottom pb-1 pt-3'>FAQ</div>
                </HashLink>    
                <Link to='/checkout'>
                    <div className='border-bottom pb-1 pt-3 mb-3'>Cart</div>
                </Link>
            </div>
        </div>    
    )
}

// Features component (Display Features which is on the home page directly before the Steps))
function Features() {
    return (
        <div>    
        {/* // Large screens display of features */}
        <section className='mt-5 footer-section features large-screens'>
                <h1 className='exp pb-3 think-text1'>Cutting Edge <span>Features</span></h1>
                <div className='row h-100 mt-5 text-left pl-4 pr-0'>
                    <div className='col d-flex flex-column h-100 pl-0 pr-0 mx-0 '>
                        <div className='feature reveal reveal2'>
                            <img src={offline_mode_icon} alt='' />
                            <h4>Offline Mode</h4>
                            <p>Download and save content to watch later</p>
                        </div>
                        <div className='feature'>
                        <img src={play_store_icon} alt='' />        
                            <h4>Full Android Play Store</h4>
                            <p>Access to several Android applications </p>
                        </div>
                        <div className='feature'>
                            <img src={productivity_icon} alt='' />        
                            <h4>Productivity</h4>
                            <p>Access to Google Workspace <br/> tools</p>
                        </div>
                    </div>
                    <div className='col d-flex flex-column px-0'>
                        <div className='d-flex justify-content-center'>
                            <img src={cyberstick_on_cylinder} className='reveal cyberstick_on_cylinder mx-auto' 
                            alt='' height='100%'/>
                        </div>
                        <div className='black-filter mx-auto'></div>
                        <div className='d-flex  justify-content-between'>
                            <div className='feature mt-5 mr-3'>
                                <img src={bluetooth_connectivity_icon} alt='' />        
                                <h4>Bluetooth Connectivity</h4>
                                <p>Sync all Bluetooth accessories including speakers, gamepads, keyboards,  and more</p>
                                {/* <p>Bluetooth connectivity <br/> enabled</p> */}
                            </div>    
                            <div className='feature mt-5 ml-3'>
                                <img src={portability_icon} alt='' />        
                                <h4>Portability</h4>
                                <p>Light weight, pocket sized <br/> smart device</p>
                            </div>
                        </div>
                    </div>
                    <div className='col d-flex flex-column align-items-end pl-0'>
                        <div className='feature reveal reveal3'>
                            <img src={storage_icon} alt='' />
                            <h4>Storage</h4>
                            <p>16GB Onboard memory and USB port for external hard drive which allows expansion of internal memory.</p>
                        </div>
                        <div className='feature'>
                            <img src={wifi_connectivity_icon} alt='' />        
                            <h4>Wifi Connectivity</h4>
                            <p>Connect to a wireless network and access the internet.</p>
                        </div>
                        <div className='feature'>
                            <img src={entertainment_icon} alt='' />        
                            <h4>Entertainment</h4>
                            <p>Enable users to access an endless supply of content, including Netflix, YouTube, live sports, Showmax, and more.</p>
                            {/* <p>Allows users to stream <br/> unlimited content (Netflix, <br/>
                            Live sports, Showmax, <br/> Youtube.  etc)</p> */}
                        </div>
                    </div>
                </div>
        </section>

        {/* medium to small screens display of feature} */}
        <section className='mt-5 footer-section features medium-screens'>
                <h1 className='exp pb-3 think-text1'>Cutting Edge <span>Features</span></h1>
                <div className='mt-5 feature-wrapper text-left pl-4 pr-0'>
                    <div className='px-0 mx-0 feature-sub-wrapper '>
                        <div className='feature'>
                            <img src={offline_mode_icon} alt='' />
                            <h4>Offline Mode</h4>
                            <p>Download and save content to watch later</p>
                        </div>
                        <div className='feature'>
                            <img src={play_store_icon} alt='' />        
                            <h4>Full Android Play Store</h4>
                            <p>Access to several Android applications </p>
                        </div>
                        <div className='feature'>
                            <img src={productivity_icon} alt='' />        
                            <h4>Productivity</h4>
                            <p>Access to Google Workspace <br/> tools</p>
                        </div>
                        <div className='feature'>
                            <img src={bluetooth_connectivity_icon} alt='' />        
                            <h4>Bluetooth Connectivity</h4>
                            <p>Sync all Bluetooth accessories including speakers, gamepads, keyboards,  and more</p>
                            {/* <p>Bluetooth connectivity <br/> enabled</p> */}
                        </div>    
                        <div className='feature'>
                            <img src={portability_icon} alt='' />        
                            <h4>Portability</h4>
                            <p>Light weight, pocket sized <br/> smart device</p>
                        </div>
                        <div className='feature'>
                            <img src={storage_icon} alt='' />
                            <h4>Storage</h4>
                            <p>16GB Onboard memory and USB port for external hard drive which allows expansion of internal memory.</p>
                            {/* <p>Onboard memory of 16GB | <br/> USB port for external 
                                hard <br/> drive |External memory card slot which allows expansion
                                <br/> of internal memory.</p> */}
                        </div>
                        <div className='feature'>
                            <img src={wifi_connectivity_icon} alt='' />        
                            <h4>Wifi Connectivity</h4>
                            <p>Connect to a wireless network and access the internet.</p>
                        </div>
                        <div className='feature'>
                            <img src={entertainment_icon} alt='' />        
                            <h4>Entertainment</h4>
                            <p>Enable users to access an endless supply of content, including Netflix, YouTube, live sports, Showmax, and more.</p>
                            {/* <p>Allows users to stream <br/> unlimited content (Netflix, <br/>
                            Live sports, Showmax, <br/> Youtube.  etc)</p> */}
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <img src={cyberstick_on_cylinder} className='cyberstick_on_cylinder mx-auto' 
                    alt=''/>
                </div>
                <div className='black-filter mx-auto'></div>
        </section>
        </div>
    )
}

function AdsFeatures() {
    return (
        <div>    
        {/* // Large screens display of features */}
        <section className='mt-50px footer-section features large-screens ads'>
                <h1 className='think-text1'>What does <img width={300} src={cyberstick_designed} /> offer you?</h1>
                <div className='row h-100 mt-5 pt-5'>
                    <div className='col-6'>
                        <div className='d-flex justify-content-center'>
                            <img src={cyberstick_on_cylinder2} className='fill-image cyberstick_on_cylinder2 mx-auto' 
                            alt='image'/>
                        </div>
                    </div>
                    <div className='col d-flex flex-column h-100'>
                        <div className='feature'>
                            <img src={ic_4k} alt='' />
                            <p>HD and 4K Streaming Without Any Buffering</p>
                        </div>
                        <div className='feature'>
                            <img src={storage_icon} alt='' />
                            <p className='start'>16GB Onboard Memory With External Enabled SD Card Option</p>
                        </div>
                        <div className='feature'>
                            <img src={play_store_icon} alt='' />        
                            <p>Full Android Play Store</p>
                        </div>
                        <div className='feature'>
                            <img src={ic_quad} alt='' />        
                            <p>Quad Core Processor Of 2GB</p>
                        </div>
                    </div>
                    <div className='col d-flex flex-column align-items-end'>
                        <div className='feature'>
                            <img src={play_store_icon} alt='' />
                            <p>Full Access to Google Play Store</p>
                        </div>
                        <div className='feature'>
                            <img src={wifi_connectivity_icon} alt='' />        
                            <p className='text-start'>Browse and Surf the Internet On Your Tv. Eg YouTube, Google, Facebook and More.</p>
                        </div>
                        <div className='feature'>
                            <img src={entertainment_icon} alt='' />        
                            <p>Unlimited Access to Thousands Of Streaming Channels Eg Netflix, Hulu, Amazon Prime Video, Disney+, HBO Max and More</p>
                        </div>
                        <div className='feature'>
                            <img src={ic_stream} alt='' />        
                            <p>Stream Live Matches</p>
                        </div>
                    </div>
                </div>
        </section>

        {/* medium to small screens display of feature} */}
        <section className='mt-5 footer-section features medium-screens'>
                <h1 className='think-text1 cyberstick_designed'>What does <img src={cyberstick_designed} /> offer you?</h1>
                <div className='d-flex justify-content-center'>
                    <img src={cyberstick_on_cylinder2} className='cyberstick_on_cylinder mx-auto' 
                    alt=''/>
                </div>
                <div className='mt-5 text-left pl-4 pr-0'>
                    <div className='px-0 mx-0 feature-sub-wrapper '>
                        <div className='feature'>
                            <img src={ic_4k} alt='' />
                            <p>HD and 4K Streaming Without Any Buffering</p>
                        </div>
                        <div className='feature'>
                            <img src={storage_icon} alt='' />
                            <p className='start'>16GB Onboard Memory With External Enabled SD Card Option</p>
                        </div>
                        <div className='feature'>
                            <img src={play_store_icon} alt='' />        
                            <p>Full Android Play Store</p>
                        </div>
                        <div className='feature'>
                            <img src={ic_quad} alt='' />        
                            <p>Quad Core Processor Of 2GB</p>
                        </div>
                        <div className='feature'>
                            <img src={play_store_icon} alt='' />
                            <p>Full Access to Google Play Store</p>
                        </div>
                        <div className='feature'>
                            <img src={wifi_connectivity_icon} alt='' />        
                            <p className='text-start'>Browse and Surf the Internet On Your Tv. Eg YouTube, Google, Facebook and More.</p>
                        </div>
                        <div className='feature'>
                            <img src={entertainment_icon} alt='' />        
                            <p>Unlimited Access to Thousands Of Streaming Channels Eg Netflix, Hulu, Amazon Prime Video, Disney+, HBO Max and More</p>
                        </div>
                        <div className='feature'>
                            <img src={ic_stream} alt='' />        
                            <p>Stream Live Matches</p>
                        </div>
                    </div>
                </div>
                <div className='black-filter mx-auto'></div>
        </section>
        </div>
    )

}

// Steps component (Display steps (which is usually directly before the FAQs))
function Steps() {
    return (
        <section className='footer-section d-flex flex-column align-items-center text-center'>
            <h1 className='exp think-text1'>
                Experience <span>Cyberstick™</span> <br/>In 3 Steps
            </h1>
            <div className='steparrow1-wrapper mb-5'>
                    <img src={steparrow1} className="steparrow1" alt=""/>
                </div>
            <section className='d-flex align-items-center flex-column'>
                <div className='row stepwrapper'>
                    <div className='col-md-3 d-flex flex-column align-items-center stepcontainer
                    mx-2vw'>
                        <span className='stepnumber'>1</span>    
                        <img src={step2} className="step" alt=""/>
                        <img src={frame1} className='frame position-absolute' alt=""/>
                        <span>Connect the</span>
                        <span>Cyberstick™  to your TV</span>
                    </div>    
                    <div className='col-md-3 d-flex flex-column align-items-center stepcontainer
                    mx-2vw'>
                        <span className='stepnumber'>2</span>    
                        <img src={step2} className="step" alt=""/>
                        <img src={frame2} className='frame position-absolute' alt=""/>
                        <span>Connect to Wi-Fi </span>
                    </div>
                    <div className='col-md-3 d-flex flex-column align-items-center stepcontainer
                    mx-2vw'>
                        <span className='stepnumber'>3</span>        
                        <img src={step3} className="step" alt=""/>
                        <img src={frame3} className='frame position-absolute mt-auto' alt=""/>
                        <span>Start Watching</span>
                    </div>
                </div>
            </section>
            <div className='steparrow1-wrapper'>
                    <img src={steparrow2} className="steparrow2" alt=""/>
            </div>
        </section>
    )
}


function AdsSteps() {
    

    return (
        <section className='d-flex flex-column align-items-center text-center ads-steps'>
            <h1 className='exp think-text1'>
                Quick And Easy Setup
            </h1>
            
            <div className='container-fluid steps mt-5'>
                <div className='row align-items-center'>
                    <div className='col num reveal reveal3'>
                        <img className='num-step' src={ic_01} />
                    </div>
                    <div className='col-7 px-0 reveal reveal2'>
                        <img className=' fill-image' src={ic_01img} />
                    </div>
                </div>
                <div className='row  align-items-center mt-5'>
                    <div className='col-7 px-0 reveal reveal3'>
                        <img className=' fill-image' src={ic_02img} />
                    </div>    

                    <div className='col num reveal reveal2'>
                        <img className='num-step2' src={ic_02} />
                    </div>
                   
                </div>
                <div className='row  align-items-center mt-5'>
                    <div className='col num reveal reveal3'>
                        <img className='num-step' src={ic_03} />
                    </div>
                    <div className='col-7 px-0 reveal reveal2'>
                        <img className=' fill-image' src={ic_03img} />
                    </div>
                </div>
            </div>
        </section>
    )
}


// FAQ component (Displays FAQs)
class Faq extends React.Component {
    // shows or hides an faq answer
    showText = (e) => {
        let text;
        let arrow;
        switch(e.target.classList[0]) {
            case 'faq-header-wrapper':
                text = e.target.nextElementSibling;
                arrow = e.target.lastElementChild;
                break;
            case 'faq-header':
                text = e.target.parentElement.nextElementSibling;
                arrow = e.target.nextElementSibling;                
                break;    
            case 'arrow':    
                text = e.target.parentElement.nextElementSibling;
                arrow = e.target;
                break;
            case 'stroke-1':
            case 'stroke-2':    
                text = e.target.parentElement.parentElement.nextElementSibling;
                arrow = e.target.parentElement
                break;
            default:
                console.log('reached default');
                break;
        }

        if (text.classList.contains('hide-answer')) {
            text.classList.remove('hide-answer')
            arrow.classList.remove('rotate')
        }
        else {
            text.classList.add('hide-answer')    
            arrow.classList.add('rotate')
        }
    };
        
    render() {
        return (
            <section className='faq d-flex flex-column align-items-md-start align-items-center text-left pl-7vw pr-7vw '
            id='faq'>
                <h4 className='big-text2 faq-title mb-30px small-caps'>faq</h4>
                <div className='faq-item py-3'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>What is the subscription fee for Cyberstick?
                        </p>
                        <div className='arrow rotate position-absolute '>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>Zero subscription fee, Cyberstick 
                    is a one-time purchase smart device with no subscription fees. </div>
                </div>
                <div className='faq-item py-3'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>How do I connect the Cyberstick?</p>
                        <div className='arrow rotate position-absolute '>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>Plug your Cyberstick to a power source 
                    (using the port at the side) and then attach it to the HDMI port of your TV or 
                    Monitor. A blue light comes on indicating that the Cyberstick is powered up. If 
                    your TV or Monitor is hung on a wall, there is an extender cable that comes with 
                    the package, attach it to the Cyberstick and plug it into your device.</div>
                </div>
                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>How do I use the Cyberstick? </p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>With the remote control that comes with 
                    the package, navigate to the settings section, connect to a Wi-Fi service and you can 
                    start streaming contents online.</div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>Do I need to own a Smart TV for Cyberstick?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>No, Cyberstick can turn any display/non-smart TV to a smart TV. </div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>Can I stream offline contents with Cyberstick?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>Yes, you can download and watch contents offline.</div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>Can I download other applications that do not come with Cyberstick?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'> Yes, other applications can be downloaded, 
                    either from the Google Playstore or from the browser.</div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>Do I need to subscribe monthly for Cyberstick? </p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>No, there are no subscription fees attached to Cyberstick.</div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>What is the storage capacity of the Cyberstick?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>Cyberstick comes with an in-built memory space of 16GB, 
                    with an external slot for a memory card or hard drive. </div>
                </div>

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>Can I connect other devices to it?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>Yes, Cyberstick has a Bluetooth setup with which 
                    you can connect your Bluetooth speaker and any other device.</div>
                </div>                

                <div className='faq-item py-4'>
                    <div className='faq-header-wrapper d-flex position-relative pointer-cursor' 
                    onClick={(e) => {this.showText(e)}}>
                        <p className='faq-header'>What productivity feature can I explore with Cyberstick?</p>
                        <div className='arrow rotate position-absolute'>
                            <div className='stroke-1'></div>
                            <div className='stroke-2'></div>
                        </div>
                    </div>    
                    <div className='faq-answer hide-answer'>With Cyberstick, you can have access to your 
                    Google Workspace tools anytime, anywhere.</div>
                </div>                                
            </section>
        )
    }
}

// Footer component (Display contact and copyright informations)
function Footer() {
    return (
        <footer className='footer container'>
            <div className='footercontainer row align-items-start 
            justify-content-between'>
                <div className='col-md-3 d-flex flex-column '>
                    <img src={logo} style={{width: '60%'}} className='footer-logo' alt='logo'/>
                </div>
                <div className='col-md-3 col-6 d-flex flex-column'>
                    <h5 className='p-0 mt-md-0 fw-600'>contact us</h5>
                    <a href='tel:+2349088999099' className='py-1 m-0'>(+234) 908 899 9099</a>
                    {/* <a href='mailto:support@cyberstick.io' target='_blank' rel='noreferrer' className='py-1 m-0'>support@cyberstick.io</a> */}
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLScrun_vK7yzIG4vpPVxr30iMYDulJn0QbEK4AZoGS5WmqpfUw/viewform' target='_blank' rel='noreferrer' className='py-1 m-0'>support@Cyberstick.io</a>
                    <a href='mailto:sales@cyberstick.io' target='_blank' rel='noreferrer' className='py-1 m-0'>sales@cyberstick.io</a>
                </div>
                <div className='col-md-3 col-6 d-flex flex-column'>
                    <h5 className='p-0 mt-md-0 fw-600'>socials</h5> 
                    <a rel='noreferrer' target='_blank' className='ig py-1 m-0' href='https://www.instagram.com/cyberstick.io/'>
                        Instagram
                    </a>
                    <a rel='noreferrer' target='_blank' className='tw py-1 m-0' href='https://www.twitter.com/Cyberstick_io'>
                        Twitter
                    </a>
                    <a rel='noreferrer' target='_blank' className='fb py-1 m-0' href='https://www.facebook.com/Cyberstickio-100203956085984/'>
                        Facebook
                    </a>
                    <a rel='noreferrer' target='_blank' className='yt py-1 m-0' href='https://youtube.com/channel/UCAvjUB_HpH77JcBvEKfhZFA'>
                        Youtube
                    </a>
                </div>
                <div className='col-md-3 col-6 d-flex flex-column'>
                    <h5 className='p-0 mt-md-0 fw-600'>information</h5> 
                    <Link target='_blank' className='py-1 m-0' to='/TermsandConditions'>
                        Terms And Conditions
                    </Link>
                    <Link target='_blank' className='py-1 m-0' to='/PrivacyPolicy'>
                        Privacy Policy
                    </Link>
                </div>
            </div>  
            <div className='copyrights d-flex flex-wrap justify-content-center align-items-center'>    
                <span>Copyright <img className='copy my-3' src={copyright} alt=""/> 2022 Cyberstick™, All rights reserved</span>
            </div>  
        </footer>
    )
}

// Display Checkout form details that is usually shown on almost every page except from the homepage
function CheckoutForm(props) {
    return(
        <form onSubmit={(e) => {e.preventDefault();}}>
            <div className='checkout-details py-2 card'>
                <div className='card-body checkout-card px-0 mx-4'>
                    <h5 className='small-caps'>{props.header}</h5>    
                    <div className="input-group mb-3 discount-code-wrapper my-3">
                        <input placeholder="Enter Referral Code" className="form-control discount-code"
                        id="discount-code" />
                        <div className="input-group-append pointer-cursor">
                            <span className="input-group-text apply-text">
                                Apply
                            </span>
                        </div>
                    </div>    
                    <div className='d-flex my-2'>
                        <div className='mr-auto'>Subtotal</div>
                        <div><NumberFormat value={props.price}
                displayType={'text'} thousandSeparator={true} prefix={'₦'} /></div>
                    </div>
                    <div className='d-flex my-2'>
                        <div className='mr-auto'>Shipping</div>
                        <div><NumberFormat value={props.shipping}
                displayType={'text'} thousandSeparator={true} prefix={'₦'} /></div>
                    </div>
                    <div className='d-flex my-2'>
                        <div className='mr-auto'>Estimated Tax</div>
                        <div>{props.tax}</div>
                    </div>
                </div>
                <div className='card-footer px-0 mx-4'>
                    <div className='d-flex'>
                        <div className='mr-auto fw-600 fs-1p3em'>Total</div>
                        <div className='fw-700 fs-1p3em'><NumberFormat value={Number(props.price) + Number(props.shipping)}
                displayType={'text'} thousandSeparator={true} prefix={'₦'} /></div>
                    </div>
                </div>
            </div>
            
        </form>
    )    
}

function RemoteFeatures() {
    return (
        <div>
            <h1 className='pb-3 think-text1 w-fit mx-auto text-left'><span className='purple-color-gradient'>Cyberstick</span> Remote
                <h2 className='sub-head fw-400 ml-3'>With Air Mouse</h2>
            </h1>
            <div className='container '>
                <div className='row align-items-center'>
                    <div className='col-md-6 col-12 order-md-first align-items-center justify-content-center order-last px-0 '>
                        <ul className='remote-features fs-1p3em'>
                            <li className='remote-feature py-3 px-3 text-left'>Voice control with Google voice assistant</li>
                            <li className='remote-feature py-3 px-3 text-left'>Air mouse Enabled</li>
                            <li className='remote-feature py-3 px-3 text-left'>2.4G Wireless Connection </li>
                            <li className='remote-feature py-3 px-3 text-left'>Built-in Gyroscope</li>
                        </ul>
                    </div>
                    <div className='col top-30px'>
                        <img src={skewed_remote} alt='remote' className='skewed-remote' />
                    </div>
                </div>    
            </div>
        </div>
    )
}

// Scroll pages to the top
class ScrollToTopOnMount extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }    
}

// Export variables, functions or components
export { Footer, Header, AdsHeader, Features, AdsFeatures, Steps, AdsSteps, Faq, CheckoutForm, RemoteFeatures, ScrollToTopOnMount }